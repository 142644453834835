<template>
  <section class="portfolio section-space">
    <div class="container">

      <div class="row">
        <div class="col-12">
          <div class="portfolio-main">
            <div id="portfolio-item" class="portfolio-item-active">
              <div class="cbp-item animation branding">
                <!-- Single Portfolio -->
                <div class="single-portfolio">
                  <div class="portfolio-head overlay">
                    <img src="@/cucumber/img/portfolio/portfolio-1.png" alt="#">
                  </div>
                  <div class="portfolio-content">
                    <h4><a href="javascript:void(0);">黄瓜加速秒开全球应用
                    </a></h4>
                    <p>稳定安全不封号
                    </p>
                  </div>
                </div>
                <!--/ End Single Portfolio -->
              </div>
              <div class="cbp-item branding consulting">
                <!-- Single Portfolio -->
                <div class="single-portfolio">
                  <div class="portfolio-head overlay">
                    <img src="@/cucumber/img/portfolio/portfolio-2.png" alt="#">
                  </div>
                  <div class="portfolio-content">
                    <h4><a href="javascript:void(0);">黄瓜加速保护您的IP
                    </a></h4>
                    <p>全局智能随心切换
                    </p>
                  </div>
                </div>
                <!--/ End Single Portfolio -->
              </div>
              <div class="cbp-item business marketing">
                <!-- Single Portfolio -->
                <div class="single-portfolio">
                  <div class="portfolio-head overlay">
                    <img src="@/cucumber/img/portfolio/portfolio-3.png" alt="#">
                  </div>
                  <div class="portfolio-content">
                    <h4><a href="javascript:void(0);">黄瓜加速飞一般的速度</a></h4>
                    <p>顺滑无惧晚高峰</p>
                  </div>
                </div>
                <!--/ End Single Portfolio -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    name: 'OfferCucumber',
    components: {},
    setup () {

    },
    created () {
    }
  }
</script>

<style scoped>
@media all and (min-width: 920px) {
  .portfolio-item-active {
    display: flex;
  }
}
</style>
