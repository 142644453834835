<template>
  <header class="header">
    <!-- Middle Header -->
    <div class="middle-header">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="middle-inner">
              <div class="row">
                <div class="col-lg-2 col-md-3 col-12" >
                  <!-- Logo -->
                  <div class="logo">
                    <!-- Image Logo -->
                    <div class="img-logo" style="margin-bottom: 12px">
                      <a href="javascript:void(0);">
                        <img style="width: 174px;height: 40px;" src="@/cucumber/img/logo.png" alt="#">
                      </a>
                    </div>
                  </div>
                  <div class="mobile-nav"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Middle Header -->
  </header>
</template>

<script>
  export default {
    name: 'HeaderCucumber',
    components: {
    },
    setup () {

    }

  }
</script>

<style scoped>
</style>
