<template>
  <!-- Hero Slider -->
  <section class="hero-slider style1">
    <div class="home-slider">
      <!-- Single Slider -->
      <div class="single-slider">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-8 col-12">
              <div class="welcome-text">
                <div class="hero-text">
                  <h4>晚高峰会不会卡顿？</h4>
                  <h1>黄瓜加速，大带宽不限速，晚高峰一样开4K！</h1>
                  <div class="p-text">
                    <p>为了达到最佳效果，建议多试几个节点找到自己最丝滑的那个！</p>
                  </div>
                  <div class="button">
                    <DownloadCucumber></DownloadCucumber>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ End Single Slider -->

      <div class="single-slider">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-8 col-12">
              <div class="welcome-text">
                <div class="hero-text">
                  <h4>会不会突然断线？
                  </h4>
                  <h1>黄瓜加速，采用自研加密协议+IPLC精品线路，365天一直在线！</h1>
                  <div class="p-text">
                    <p>长时间连接部分手机会自动关闭进程并非断线，重启应用就能恢复！
                    </p>
                  </div>
                  <div class="button">
                    <DownloadCucumber></DownloadCucumber>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Single Slider -->
      <div class="single-slider">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-8 col-12">
              <div class="welcome-text">
                <div class="hero-text">
                  <h4>黄瓜加速安全吗？
                  </h4>
                  <h1>团队由硅谷顶尖技术专家组成，base新加坡，无日志，用户安全无忧！</h1>
                  <div class="p-text">
                    <p>黄瓜加速安全可靠，用户无需注册即可匿名使用！
                    </p>
                  </div>
                  <div class="button">
                    <DownloadCucumber></DownloadCucumber>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ End Single Slider -->
    </div>
  </section>
  <!--/ End Hero Slider -->
</template>

<script>
import DownloadCucumber from "@/components/DownloadCucumber"
  export default {
    name: 'BannerCucumber',
    components: {
      DownloadCucumber
    },
    setup () {

    }

  }
</script>

<style scoped>
</style>
