<template>
    <a class="bizwheel-btn theme-1 effect" @click="downloadInstall()" :href="`${apkUrl}`">安卓下载</a>
</template>

<script>
  import utils from '../utils/utils'
  export default {
    name: 'DownloadCucumber',
    components: {},
    setup () {
      let env = window.env
      let apkUrl = `/apks/${env.version}/${env.app}-${env.version}-release-${env.channel}.apk`
      function downloadInstall () {
        utils.statsDownload()
      }
      return {
        downloadInstall,
        apkUrl,
        env
      }
    }
  }
</script>

<style scoped>

</style>
