<template>
  <Header></Header>
  <Banner></Banner>
  <Offer></Offer>
  <Footer></Footer>
</template>
<script>
  import Header from "./components/HeaderCucumber"
  import Banner from "./components/BannerCucumber"
  import Offer from "./components/OfferCucumber"
  import Footer from "./components/FooterCucumber"

  export default {
    name: 'AppCucumber',
    components: {
      Header,
      Banner,
      Offer,
      Footer,
    },
  }
</script>
<style>
</style>
