<template>
  <footer class="footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <!-- Footer About -->
            <div class="single-widget footer-about widget">
              <div class="logo">
                <div class="img-logo">
                  <a class="logo" href="javascript:void(0);">
                    <img class="img-responsive" style="width: 172px;height: 40px;" src="@/cucumber/img/logo2.png" alt="logo">
                  </a>
                </div>
              </div>
              <div class="footer-widget-about-description">
              </div>
              <div class="social">
                <!-- Social Icons -->
                <ul class="social-icons">
                  <li><a style="white-space: nowrap;" :href="`mailto:${email}`" target="_blank">客服邮箱: {{email}}</a></li>
                </ul>
              </div>
              <div class="button">
                <DownloadCucumber class="bizwheel-btn"></DownloadCucumber>
              </div>
            </div>
            <!--/ End Footer About -->
          </div>

        </div>
        <div class="row">
          <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright -->
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="copyright-content">
              <!-- Copyright Text -->
              <p>© Copyright {{companyName}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Copyright -->
  </footer>
</template>

<script>
import DownloadCucumber from "@/components/DownloadCucumber"
  export default {
    name: 'FooterCucumber',
    components: {DownloadCucumber},
    setup(){
    }
  }
</script>

<style scoped>

</style>
